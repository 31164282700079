<script setup lang="ts">
import 'vue-skeletor/dist/vue-skeletor.css';
import PropertyImage from '@properties/components/PropertyImage.vue';

type PropertyRowCellProps = {
  property: Domain.Properties.DataObjects.PropertyData;
  size?: 'small' | 'default' | 'large';
};

const props = withDefaults(defineProps<PropertyRowCellProps>(), {
  size: 'default',
});

const addressLineOne = computed<string | undefined>(
  () => props.property?.shortAddress
);
const addressLineTwo = computed<string>(
  () => props.property?.area?.name + ', ' + props.property?.postcode
);
</script>

<template>
  <div class="flex gap-4">
    <PropertyImage :image-url="props.property.imageUrl" :size="props.size" />
    <div class="flex flex-1 flex-col">
      <span
        class="text-base font-normal leading-5 text-gray-900 dark:text-white md:leading-6"
        >{{ addressLineOne }}</span
      >
      <span class="mb-3 text-gray-500 dark:text-gray-300">{{
        addressLineTwo
      }}</span>

      <ul class="flex gap-4 text-sm text-gray-500">
        <li>
          <FontAwesomeIcon :icon="['fas', 'bed']" class="mr-1 text-gray-300" />
          {{ props.property.roomCount }}
        </li>
        <li>
          <FontAwesomeIcon :icon="['fas', 'bath']" class="mr-1 text-gray-300" />
          {{ props.property.bathrooms }}
        </li>
        <li v-if="props.property.isFeedProperty">
          <FontAwesomeIcon
            :icon="['fas', 'house-signal']"
            class="mr-1 text-gray-300"
          />
          Ref: {{ props.property.feedReference.feed_reference }}
          <pre class="hidden">{{ props.property.feedReference }}</pre>
        </li>
      </ul>
    </div>
  </div>
</template>
